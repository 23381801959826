define("dummy/components/add-cc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Iu7in0Z",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[20,[\"toggleState\"]]],null,{\"statements\":[[0,\"\\t\"],[6,\"button\"],[9,\"class\",\"btn-link add-cc-btn\"],[9,\"type\",\"button\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"toggle\"],null],null],[7],[0,\"Add CC\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"add-cc-wrapper\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"toggleState\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"toggleState\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[6,\"button\"],[9,\"class\",\"btn-link hide-cc-btn\"],[9,\"type\",\"button\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"toggle\"],null],null],[7],[0,\"Hide CC\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dummy/components/add-cc/template.hbs"
    }
  });

  _exports.default = _default;
});