define("dummy/constants/normal-fields/checkbox-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'hasAttachments',
    label: 'Has Attachments',
    inputType: 'checkbox-field',
    editable: true,
    required: true
  };
  _exports.default = _default;
});