define("dummy/helpers/get-object-at", ["exports", "ember-dynamic-form/helpers/get-object-at"], function (_exports, _getObjectAt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _getObjectAt.default;
    }
  });
  Object.defineProperty(_exports, "getObjectAt", {
    enumerable: true,
    get: function get() {
      return _getObjectAt.getObjectAt;
    }
  });
});