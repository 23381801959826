define("dummy/components/sample-form/fields/normal-fields/radio-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'gender',
    label: 'Gender',
    editable: true,
    required: true,
    inputType: 'radio-field',
    fields: [],
    optionValuePath: 'id',
    optionLabelPath: 'label',
    getChoices: [{
      id: 'male',
      label: 'Male'
    }, {
      id: 'female',
      label: 'Female'
    }]
  };
  _exports.default = _default;
});