define("dummy/constants/autocomplete-fields/single-with-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'programmingLanguages',
    label: 'Search programming language',
    inputType: 'auto-complete',
    editable: true,
    required: true,
    fields: [],
    // optionValuePath: 'id',
    optionLabelPath: 'label',
    optionTargetPath: 'programming_languages',
    renderInPlace: true,
    link: '/search_programming_languages',
    placeholder: 'Search or create a programming language',
    fieldOptions: {
      multiple: false,
      creatable: true // suggestionOptionComponent: 'suggestion-button'

    }
  };
  _exports.default = _default;
});