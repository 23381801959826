define("dummy/models/date-field", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    dateOfBirth: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Model.extend(Validations, {
    dateOfBirth: attr('string')
  });

  _exports.default = _default;
});