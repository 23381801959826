define("dummy/utils/field-utils", ["exports", "ember-dynamic-form/utils/field-utils"], function (_exports, _fieldUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fieldUtils.default;
    }
  });
});