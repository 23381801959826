define("dummy/components/sample-form/fields/normal-fields/checkbox-group-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'degree',
    label: 'Degree',
    editable: true,
    required: true,
    inputType: 'checkbox-group',
    fields: [],
    optionValuePath: 'id',
    optionLabelPath: 'label',
    getChoices: [{
      id: 'bachelors',
      label: 'Bachelors'
    }, {
      id: 'masters',
      label: 'Masters'
    }, {
      id: 'phd',
      label: 'Phd'
    }]
  };
  _exports.default = _default;
});