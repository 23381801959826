define("dummy/router", ["exports", "ember-cli-addon-docs/router", "dummy/config/environment"], function (_exports, _router, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Router = _router.default.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    (0, _router.docsRoute)(this, function () {});
    this.route('docs', function () {
      this.route('form');
      this.route('date-field');
      this.route('date-range-field');
      this.route('date-time-field');
      this.route('autocomplete-multiple-with-create');
      this.route('autocomplete-multiple');
      this.route('autocomplete-single');
      this.route('autocomplete-single-with-create');
      this.route('select-single');
      this.route('select-multiple');
      this.route('input-field');
      this.route('textarea-field');
      this.route('checkbox-field');
      this.route('checkbox-group');
      this.route('radio-group');
      this.route('nested-field');
      this.route('dependent-field-checkbox');
      this.route('dependent-field-radio');
      this.route('dependent-field-select');
      this.route('add-cc');
    });
    this.route('not-found', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});