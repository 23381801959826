define("dummy/validators/length", ["exports", "ember-cp-validations/validators/length"], function (_exports, _length) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _length.default;
    }
  });
});