define("dummy/components/sample-form/fields/select-fields/multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'languages',
    label: 'Languages',
    editable: true,
    required: true,
    placeholder: 'Select Languages',
    inputType: 'multi-select-dropdown-field',
    fields: [],
    optionValuePath: 'id',
    optionLabelPath: 'label',
    renderInPlace: true,
    getChoices: [{
      id: 'english',
      label: 'English'
    }, {
      id: 'tamil',
      label: 'Tamil'
    }, {
      id: 'hindi',
      label: 'Hindi'
    }]
  };
  _exports.default = _default;
});