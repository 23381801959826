define("dummy/constants/render-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'addCC',
    inputType: 'render-component',
    fieldOptions: {
      componentPath: 'add-cc'
    },
    fields: [{
      name: 'cc',
      label: '',
      inputType: 'auto-complete',
      editable: true,
      required: true,
      fields: [],
      // optionValuePath: 'id',
      optionLabelPath: 'label',
      optionTargetPath: 'locations',
      renderInPlace: true,
      link: '/search_locations',
      fieldOptions: {
        multiple: true,
        creatable: false
      },
      placeholder: 'add emails',
      fieldClasses: 'add-cc'
    }]
  };
  _exports.default = _default;
});