define("dummy/components/sample-form/fields/autocomplete-fields/multiple-with-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'hobbies',
    label: 'Search hobbies',
    inputType: 'auto-complete',
    editable: true,
    required: true,
    fields: [],
    // optionValuePath: 'id',
    optionLabelPath: 'label',
    optionTargetPath: 'hobbies',
    renderInPlace: true,
    link: '/search_hobbies',
    fieldOptions: {
      multiple: true,
      creatable: true
    },
    placeholder: 'Search list of hobbies or create a hobby'
  };
  _exports.default = _default;
});