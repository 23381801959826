define("dummy/components/form-fields/url-field", ["exports", "ember-form-for/components/form-fields/url-field"], function (_exports, _urlField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _urlField.default;
    }
  });
});