define("dummy/components/field-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZyKW4v6q",
    "block": "{\"symbols\":[],\"statements\":[[6,\"strong\"],[7],[0,\"Tooltip\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dummy/components/field-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});