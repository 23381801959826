define("dummy/templates/docs/select-single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hcz7e+hi",
    "block": "{\"symbols\":[],\"statements\":[[1,[18,\"fields/select-single\"],false],[0,\"\\n\"],[6,\"br\"],[7],[8],[0,\"\\n\"],[6,\"h1\"],[7],[6,\"strong\"],[7],[0,\"Config\"],[8],[8],[0,\"\\n\"],[1,[25,\"docs-snippet\",null,[[\"name\",\"showCopy\"],[\"select-fields/single.js\",true]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dummy/templates/docs/select-single.hbs"
    }
  });

  _exports.default = _default;
});