define("dummy/components/form-for", ["exports", "ember-form-for/components/form-for"], function (_exports, _formFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _formFor.default;
    }
  });
});