define("dummy/helpers/sort-fields-by", ["exports", "ember-dynamic-form/helpers/sort-fields-by"], function (_exports, _sortFieldsBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sortFieldsBy.default;
    }
  });
  Object.defineProperty(_exports, "sortFieldsBy", {
    enumerable: true,
    get: function get() {
      return _sortFieldsBy.sortFieldsBy;
    }
  });
});