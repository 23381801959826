define("dummy/components/sample-form/fields/date-picker-fields/date-range-field", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // The ISO 8601 syntax (YYYY-MM-DD) for setting minDate & maxDate
  // Use service moment for setting defaultValue/minDate/maxDate
  // Set defaultDate in UTC
  var _default = {
    label: 'Select time period',
    // if no placeholder is givem dateFormat is taken as placeholder
    placeholder: 'Select date range',
    name: 'dateRange',
    editable: true,
    required: true,
    disableCamelize: true,
    inputType: 'date-range-field',
    fieldOptions: {
      allowClear: true,
      fieldAlign: true,
      inlineReset: true,
      // this will be removed once intl is introduced in form-serv addon
      translations: {
        clear: 'Clear',
        cancel: 'Cancel',
        update: 'Update',
        year: 'Year',
        month: 'Month'
      },
      // taken from current account
      dateFormat: 'DD MMM, YYYY',
      // always pass moment objects
      // use getMinDate & getMaxDate util functions from app/constants/date-time.js
      minDate: (0, _moment.default)(new Date("2011-01-01")).startOf('day'),
      maxDate: (0, _moment.default)(),
      defaultValue: {// from: moment().subtract(1, 'months').startOf('day').toISOString(),
        // to: moment().startOf('day').toISOString()
      },
      // used in case of custom positioning is required
      // js: app/constants/common/form-callbacks/list.js
      // css: app/styles/form-components/_date-picker.scss
      // current positionClass used in list page - repositionfor-sidebar
      positionClass: '',
      // reuse same class or add class to above scss file
      calculatePosition: function calculatePosition() {},
      // calculatePosition
      calendarToggle: function calendarToggle() {} // calendarToggle

    },
    fields: [{
      name: 'from',
      fieldOptions: {
        isFromDate: true
      },
      fields: []
    }, {
      name: 'to',
      fieldOptions: {
        isFromDate: false
      },
      fields: []
    }]
  };
  _exports.default = _default;
});