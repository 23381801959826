define("dummy/helpers/fserv-is-none", ["exports", "ember-dynamic-form/helpers/fserv-is-none"], function (_exports, _fservIsNone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fservIsNone.default;
    }
  });
  Object.defineProperty(_exports, "fservIsNone", {
    enumerable: true,
    get: function get() {
      return _fservIsNone.fservIsNone;
    }
  });
});