define("dummy/serializers/form", ["exports", "ember-data", "dummy/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      fields: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});