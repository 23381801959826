define("dummy/components/fields/select-single/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gfxCphTU",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[6,\"main\"],[7],[0,\"\\n  \"],[6,\"h1\"],[9,\"id\",\"title\"],[7],[6,\"strong\"],[7],[0,\"Date Field\"],[8],[8],[0,\"\\n\"],[4,\"dynamic-form-for\",null,[[\"class\",\"model\",\"schema\",\"fieldNameToHintComponentMap\",\"save\"],[\"ember-form\",[20,[\"model\"]],[20,[\"schema\"]],[20,[\"fieldNameToHintComponentMap\"]],[25,\"action\",[[19,0,[]],\"save\",[20,[\"model\"]]],null]]],{\"statements\":[[0,\"      \"],[1,[19,1,[\"drawForm\"]],false],[0,\"\\n      \"],[1,[25,\"component\",[[19,1,[\"submit\"]],\"Save\"],null],false],[0,\"\\n      \"],[6,\"button\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"updateform\"],null],null],[7],[0,\"Dynamic Update\"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dummy/components/fields/select-single/template.hbs"
    }
  });

  _exports.default = _default;
});