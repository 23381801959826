define("dummy/templates/docs/autocomplete-multiple-with-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AfGLc7uC",
    "block": "{\"symbols\":[],\"statements\":[[1,[18,\"fields/autocomplete-multiple-with-create\"],false],[0,\"\\n\"],[6,\"br\"],[7],[8],[0,\"\\n\"],[6,\"h1\"],[7],[6,\"strong\"],[7],[0,\"Config\"],[8],[8],[0,\"\\n\"],[1,[25,\"docs-snippet\",null,[[\"name\",\"showCopy\"],[\"autocomplete-fields/multiple-with-create.js\",true]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dummy/templates/docs/autocomplete-multiple-with-create.hbs"
    }
  });

  _exports.default = _default;
});