define("dummy/helpers/show-field", ["exports", "ember-dynamic-form/helpers/show-field"], function (_exports, _showField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _showField.default;
    }
  });
  Object.defineProperty(_exports, "showField", {
    enumerable: true,
    get: function get() {
      return _showField.showField;
    }
  });
});