define("dummy/constants/select-fields/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'placeOfBirth',
    label: 'Place of Birth',
    editable: true,
    required: true,
    renderInPlace: true,
    placeholder: 'Select place of birth',
    inputType: 'power-select-field',
    fields: [],
    optionValuePath: 'id',
    optionLabelPath: 'label',
    getChoices: [{
      id: 'india',
      label: 'India'
    }, {
      id: 'others',
      label: 'Others'
    }]
  };
  _exports.default = _default;
});