define("dummy/constants/normal-fields/input-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'name',
    label: 'Name',
    editable: true,
    required: true,
    inputType: 'text-field',
    placeholder: 'enter your name',
    autocomplete: 'off',
    fields: []
  };
  _exports.default = _default;
});