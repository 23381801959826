define("dummy/components/render-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C99ASzWy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"button\"],[9,\"type\",\"button\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"toggle\"],null],null],[7],[1,[25,\"if\",[[20,[\"toggleState\"]],\"Hide\",\"Show\"],null],false],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"toggleState\"]]],null,{\"statements\":[[0,\"\\t\"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dummy/components/render-component/template.hbs"
    }
  });

  _exports.default = _default;
});