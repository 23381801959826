define("dummy/components/sample-form/fields/dependent-fields/dropdown-dependent-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    alias name - Dropdown Depedent Field
    type: Dependent Field
    parent control through - dropdown
    min level - 1
    max levels - 60
    dependency through - field id
    combinations on child fields - power-select-dependent-field | checkbox-dependent-field | radio-dependent-field
    combinations on parent
    - based on selected dropdown value we can show field if field id exists
  */
  var _default = {
    label: "Choose Framework",
    name: 'framework',
    optionValuePath: 'id',
    optionLabelPath: 'value',
    placeholder: "Select a Framework",
    inputType: "power-select-dependent-field",
    renderInPlace: true,
    editable: true,
    getChoices: [{
      id: 'framework_no',
      value: 'No Framework',
      dependentIds: {
        field: []
      }
    }, {
      id: 'framework_yes',
      value: 'Frontend Framework',
      dependentIds: {
        field: ['frameworks']
      }
    }],
    fields: [{
      id: 'frameworks',
      name: 'feFrameworks',
      label: 'Select a framework/library',
      placeholder: 'Select a framework/library',
      editable: true,
      required: true,
      renderInPlace: true,
      inputType: 'power-select-dependent-field',
      fields: [],
      optionValuePath: 'id',
      optionLabelPath: 'label',
      getChoices: [{
        id: 'ember',
        label: 'Ember'
      }, {
        id: 'react',
        label: 'React'
      }, {
        id: 'vue',
        label: 'Vue'
      }]
    }]
  };
  _exports.default = _default;
});