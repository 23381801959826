define("dummy/components/sample-form/fields/dependent-fields/checkbox-dependent-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    alias name - Checkbox Depedent Field
    type: Dependent Field
    parent control through - checkbox
    min level - 1
    max levels - 60
    dependency through - field id
    combinations on child fields - power-select-dependent-field | checkbox-dependent-field | radio-dependent-field
    combinations on parent
    - true => show field when checked or hide field when unchecked
    - false => show field when uncheked or hide field when checked
    - true/false => show one field when checked and show another field when unchecked
  */
  var _default = {
    label: "Choose mode of Payment",
    name: 'addPaymentType',
    optionLabelPath: 'label',
    inputType: 'checkbox-dependent-field',
    editable: true,
    required: true,
    getChoices: [{
      label: 'true',
      dependentIds: {
        field: ['bank']
      }
    }],
    fields: [{
      id: 'bank',
      name: 'bank',
      label: 'Select a bank',
      placeholder: 'Select a bank',
      editable: true,
      required: true,
      renderInPlace: true,
      inputType: 'power-select-dependent-field',
      fields: [],
      optionValuePath: 'id',
      optionLabelPath: 'label',
      getChoices: [{
        id: 'sbi',
        label: 'SBI'
      }, {
        id: 'hdfc',
        label: 'HDFC'
      }]
    }]
  };
  _exports.default = _default;
});