define("dummy/components/add-cc/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    toggleState: false,
    actions: {
      toggle: function toggle(e) {
        e.preventDefault();
        Ember.set(this, 'toggleState', !this.toggleState);
      }
    }
  });

  _exports.default = _default;
});