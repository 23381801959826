define("dummy/components/sample-form/fields/date-picker-fields/date-field", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    editable: true,
    required: true,
    hint: true,
    inputType: 'date-field',
    fields: [],
    placeholder: 'Date of birth',
    // if no placeholder is givem dateFormat is taken as placeholder
    fieldOptions: {
      allowClear: true,
      // minDate and maxDate should be given in localmoment
      // minDate: moment(this.get('moment').moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')
      // minDate: moment(),
      // maxDate: moment('2030-12-31', 'YYYY-MM-DD'),
      dateFormat: 'DD-MM-YYYY'
    }
  };
  _exports.default = _default;
});