define("dummy/constants/dependent-fields/nested-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    alias name - Nested Field
    type: Dependent Field
    control through - Dropdown
    min levels - 3
    max levels - 60
    dependency through - choice id
    combinations on child fields - power-select-dependent-field
    combinations on parent
    - based on choice id mapping (refer example)
  */
  var _default = {
    'name': 'jobType',
    'placeholder': 'Select Job Type',
    'inputType': 'power-select-dependent-field',
    'editable': true,
    'required': true,
    'optionValuePath': 'id',
    'optionLabelPath': 'label',
    'renderInPlace': true,
    'getChoices': [{
      'id': 'ic',
      'label': 'Individual Contributor',
      'dependentIds': {
        'choice': ['fe', 'be', 'de']
      }
    }, {
      'id': 'pm',
      'label': 'People Management',
      'dependentIds': {
        'choice': ['em', 'ed']
      }
    }],
    'fields': [{
      'name': 'jobDesignation',
      'placeholder': 'Select Designation',
      'inputType': 'power-select-dependent-field',
      'editable': true,
      'required': true,
      'optionValuePath': 'id',
      'optionLabelPath': 'label',
      'renderInPlace': true,
      'getChoices': [{
        'id': 'fe',
        'label': 'Frontend Engineer',
        'dependentIds': {
          'choice': ['ic1', 'ic2', 'ic3']
        }
      }, {
        'id': 'be',
        'label': 'Backend Engineer',
        'dependentIds': {
          'choice': ['ic1', 'ic2', 'ic3']
        }
      }, {
        'id': 'de',
        'label': 'Devops Engineer',
        'dependentIds': {
          'choice': ['ic1', 'ic2', 'ic3']
        }
      }, {
        'id': 'em',
        'label': 'Engineering Manager',
        'dependentIds': {
          'choice': ['pm1', 'pm2', 'pm3']
        }
      }, {
        'id': 'ed',
        'label': 'Engineering Director',
        'dependentIds': {
          'choice': ['pm1', 'pm2', 'pm3']
        }
      }],
      'fields': [{
        'name': 'jobLevel',
        'placeholder': 'Select Job Level',
        'inputType': 'power-select-dependent-field',
        'editable': true,
        'required': true,
        'optionValuePath': 'id',
        'optionLabelPath': 'label',
        'renderInPlace': true,
        'getChoices': [{
          'id': 'ic1',
          'label': 'IC1'
        }, {
          'id': 'ic2',
          'label': 'IC2'
        }, {
          'id': 'ic3',
          'label': 'IC3'
        }, {
          'id': 'pm1',
          'label': 'PM1'
        }, {
          'id': 'pm2',
          'label': 'PM2'
        }, {
          'id': 'pm3',
          'label': 'PM3'
        }],
        'fields': []
      }]
    }]
  };
  _exports.default = _default;
});