define("dummy/components/code-snippet/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RTZ0xs9x",
    "block": "{\"symbols\":[],\"statements\":[[6,\"pre\"],[7],[6,\"code\"],[10,\"class\",[26,[\"language-js \",[25,\"if\",[[20,[\"toggleState\"]],\"\",\"hide\"],null]]]],[7],[1,[18,\"code\"],true],[8],[8],[0,\"\\n\"],[6,\"button\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"toggle\"],null],null],[7],[1,[25,\"if\",[[20,[\"toggleState\"]],\"Hide\",\"Show\"],null],false],[8],[0,\"\\n\"],[6,\"button\"],[10,\"onclick\",[25,\"action\",[[19,0,[]],\"copy\",[20,[\"code\"]]],null],null],[7],[0,\"Copy\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dummy/components/code-snippet/template.hbs"
    }
  });

  _exports.default = _default;
});