define("dummy/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IyiJeh/r",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"docs-landing-page\"],[7],[0,\"\\n  \"],[6,\"p\"],[9,\"style\",\"font-size:24px;\"],[7],[0,\"This page doesn't exist. \"],[4,\"docs-link\",[\"index\"],null,{\"statements\":[[0,\"Go to home\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dummy/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});