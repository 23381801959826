define("dummy/constants/dependent-fields/radio-dependent-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    alias name - Radio Depedent Field
    type: Dependent Field
    parent control through - checkbox
    min level - 1
    max levels - 60
    dependency through - field id
    combinations on child fields - power-select-dependent-field | checkbox-dependent-field | radio-dependent-field
    combinations on parent
    - based on selected radio value we can show field if field id exists
  */
  var _default = {
    label: "Choose product",
    name: 'products',
    optionValuePath: 'id',
    optionLabelPath: 'label',
    inputType: 'radio-dependent-field',
    editable: true,
    required: true,
    getChoices: [{
      id: 'fs',
      label: 'Freshservice',
      dependentIds: {
        field: ['fs']
      }
    }, {
      id: 'fd',
      label: 'Freshdesk',
      dependentIds: {
        field: ['fd']
      }
    }, {
      id: 'fc',
      label: 'FreshChat',
      dependentIds: {
        field: []
      }
    }],
    fields: [{
      id: 'fs',
      name: 'fsPlan',
      label: 'Choose Freshservice Plan',
      placeholder: 'Choose fs plan',
      editable: true,
      required: true,
      renderInPlace: true,
      inputType: 'power-select-dependent-field',
      fields: [],
      optionValuePath: 'id',
      optionLabelPath: 'label',
      getChoices: [{
        id: 'startup',
        label: 'Startup'
      }, {
        id: 'estate',
        label: 'Estate'
      }]
    }, {
      id: 'fd',
      name: 'fdPlan',
      label: 'Choose Freshdesk Plan',
      placeholder: 'Choose fd plan',
      editable: true,
      required: true,
      renderInPlace: true,
      inputType: 'power-select-dependent-field',
      fields: [],
      optionValuePath: 'id',
      optionLabelPath: 'label',
      getChoices: [{
        id: 'sprout',
        label: 'Sprout'
      }, {
        id: 'enterprise',
        label: 'Enterprise'
      }]
    }]
  };
  _exports.default = _default;
});