define("dummy/constants/timezone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'American Samoa': 'Pacific/Pago_Pago',
    'Samoa': 'Pacific/Apia',
    'International Date Line West': 'Pacific/Midway',
    'Midway Island': 'Pacific/Midway',
    'Hawaii': 'Pacific/Honolulu',
    'Alaska': 'America/Juneau',
    'Pacific Time (US & Canada)': 'America/Los_Angeles',
    'Tijuana': 'America/Tijuana',
    'Arizona': 'America/Phoenix',
    'Chihuahua': 'America/Chihuahua',
    'Mazatlan': 'America/Mazatlan',
    'Mountain Time (US & Canada)': 'America/Denver',
    'Central America': 'America/Guatemala',
    'Central Time (US & Canada)': 'America/Chicago',
    'Guadalajara': 'America/Mexico_City',
    'Mexico City': 'America/Mexico_City',
    'Monterrey': 'America/Monterrey',
    'Saskatchewan': 'America/Regina',
    'Bogota': 'America/Bogota',
    'Eastern Time (US & Canada)': 'America/New_York',
    'Indiana (East)': 'America/Indiana/Indianapolis',
    'Lima': 'America/Lima',
    'Quito': 'America/Lima',
    'Atlantic Time (Canada)': 'America/Halifax',
    'Caracas': 'America/Caracas',
    'Georgetown': 'America/Guyana',
    'La Paz': 'America/La_Paz',
    'Santiago': 'America/Santiago',
    'Newfoundland': 'America/St_Johns',
    'Brasilia': 'America/Sao_Paulo',
    'Buenos Aires': 'America/Argentina/Buenos_Aires',
    'Greenland': 'America/Godthab',
    'Montevideo': 'America/Montevideo',
    'Mid-Atlantic': 'Atlantic/South_Georgia',
    'Azores': 'Atlantic/Azores',
    'Cape Verde Is.': 'Atlantic/Cape_Verde',
    'Casablanca': 'Africa/Casablanca',
    'Dublin': 'Europe/Dublin',
    'Edinburgh': 'Europe/London',
    'Lisbon': 'Europe/Lisbon',
    'London': 'Europe/London',
    'Monrovia': 'Africa/Monrovia',
    'UTC': 'Etc/UTC',
    'Amsterdam': 'Europe/Amsterdam',
    'Belgrade': 'Europe/Belgrade',
    'Berlin': 'Europe/Berlin',
    'Bern': 'Europe/Berlin',
    'Bratislava': 'Europe/Bratislava',
    'Brussels': 'Europe/Brussels',
    'Budapest': 'Europe/Budapest',
    'Copenhagen': 'Europe/Copenhagen',
    'Ljubljana': 'Europe/Ljubljana',
    'Madrid': 'Europe/Madrid',
    'Paris': 'Europe/Paris',
    'Prague': 'Europe/Prague',
    'Rome': 'Europe/Rome',
    'Sarajevo': 'Europe/Sarajevo',
    'Skopje': 'Europe/Skopje',
    'Stockholm': 'Europe/Stockholm',
    'Vienna': 'Europe/Vienna',
    'Warsaw': 'Europe/Warsaw',
    'West Central Africa': 'Africa/Algiers',
    'Zagreb': 'Europe/Zagreb',
    'Athens': 'Europe/Athens',
    'Bucharest': 'Europe/Bucharest',
    'Cairo': 'Africa/Cairo',
    'Harare': 'Africa/Harare',
    'Helsinki': 'Europe/Helsinki',
    'Jerusalem': 'Asia/Jerusalem',
    'Kaliningrad': 'Europe/Kaliningrad',
    'Kyiv': 'Europe/Kiev',
    'Pretoria': 'Africa/Johannesburg',
    'Riga': 'Europe/Riga',
    'Sofia': 'Europe/Sofia',
    'Tallinn': 'Europe/Tallinn',
    'Vilnius': 'Europe/Vilnius',
    'Baghdad': 'Asia/Baghdad',
    'Istanbul': 'Europe/Istanbul',
    'Kuwait': 'Asia/Kuwait',
    'Minsk': 'Europe/Minsk',
    'Moscow': 'Europe/Moscow',
    'Nairobi': 'Africa/Nairobi',
    'Riyadh': 'Asia/Riyadh',
    'St. Petersburg': 'Europe/Moscow',
    'Tehran': 'Asia/Tehran',
    'Abu Dhabi': 'Asia/Muscat',
    'Baku': 'Asia/Baku',
    'Muscat': 'Asia/Muscat',
    'Samara': 'Europe/Samara',
    'Tbilisi': 'Asia/Tbilisi',
    'Volgograd': 'Europe/Volgograd',
    'Yerevan': 'Asia/Yerevan',
    'Kabul': 'Asia/Kabul',
    'Ekaterinburg': 'Asia/Yekaterinburg',
    'Islamabad': 'Asia/Karachi',
    'Karachi': 'Asia/Karachi',
    'Tashkent': 'Asia/Tashkent',
    'Chennai': 'Asia/Kolkata',
    'Kolkata': 'Asia/Kolkata',
    'Mumbai': 'Asia/Kolkata',
    'New Delhi': 'Asia/Kolkata',
    'Sri Jayawardenepura': 'Asia/Colombo',
    'Kathmandu': 'Asia/Kathmandu',
    'Almaty': 'Asia/Almaty',
    'Astana': 'Asia/Dhaka',
    'Dhaka': 'Asia/Dhaka',
    'Urumqi': 'Asia/Urumqi',
    'Rangoon': 'Asia/Rangoon',
    'Bangkok': 'Asia/Bangkok',
    'Hanoi': 'Asia/Bangkok',
    'Jakarta': 'Asia/Jakarta',
    'Krasnoyarsk': 'Asia/Krasnoyarsk',
    'Novosibirsk': 'Asia/Novosibirsk',
    'Beijing': 'Asia/Shanghai',
    'Chongqing': 'Asia/Chongqing',
    'Hong Kong': 'Asia/Hong_Kong',
    'Irkutsk': 'Asia/Irkutsk',
    'Kuala Lumpur': 'Asia/Kuala_Lumpur',
    'Perth': 'Australia/Perth',
    'Singapore': 'Asia/Singapore',
    'Taipei': 'Asia/Taipei',
    'Ulaan Bataar': 'Asia/Ulaanbaatar',
    'Osaka': 'Asia/Tokyo',
    'Sapporo': 'Asia/Tokyo',
    'Seoul': 'Asia/Seoul',
    'Tokyo': 'Asia/Tokyo',
    'Yakutsk': 'Asia/Yakutsk',
    'Adelaide': 'Australia/Adelaide',
    'Darwin': 'Australia/Darwin',
    'Brisbane': 'Australia/Brisbane',
    'Canberra': 'Australia/Melbourne',
    'Guam': 'Pacific/Guam',
    'Hobart': 'Australia/Hobart',
    'Melbourne': 'Australia/Melbourne',
    'Port Moresby': 'Pacific/Port_Moresby',
    'Sydney': 'Australia/Sydney',
    'Vladivostok': 'Asia/Vladivostok',
    'Magadan': 'Asia/Magadan',
    'New Caledonia': 'Pacific/Noumea',
    'Solomon Is.': 'Pacific/Guadalcanal',
    'Srednekolymsk': 'Asia/Srednekolymsk',
    'Auckland': 'Pacific/Auckland',
    'Fiji': 'Pacific/Fiji',
    'Kamchatka': 'Asia/Kamchatka',
    'Marshall Is.': 'Pacific/Majuro',
    'Wellington': 'Pacific/Auckland',
    'Chatham Is.': 'Pacific/Chatham',
    "Nuku'alofa": 'Pacific/Tongatapu',
    'Tokelau Is.': 'Pacific/Fakaofo'
  };
  _exports.default = _default;
});