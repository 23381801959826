define("dummy/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.hasClass('modal-fade-and-drop'), this.use('fadeAndDrop'));
    this.transition(this.hasClass('modal-fade'), this.use('fade', {
      duration: 150
    }));
  }
});