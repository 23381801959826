define("dummy/constants/date-picker-fields/date-time-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'dateTime',
    label: 'Date & Time',
    editable: true,
    required: true,
    inputType: 'date-time-split-field',
    fields: [],
    fieldOptions: {
      allowClear: true,
      // minDate: moment('01 Jun, 2021'),
      // maxDate: moment('31 Jul, 2022'),
      startMonth: moment('01-07-2015', 'DD-MM-YYYY').month(),
      endMonth: moment('31-07-2016', 'DD-MM-YYYY').month(),
      startYear: 2015,
      endYear: 2016,
      dateFormat: 'DD-MM-YYYY',
      placeholder: {
        date: 'Select date',
        // if no placeholder is given dateFormat is taken as placeholder
        time: 'Select time' // if no placeholder is given HH:MM is taken as placeholder

      }
    }
  };
  _exports.default = _default;
});