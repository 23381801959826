define("dummy/constants/date-picker-fields/date-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    editable: true,
    required: true,
    hint: true,
    inputType: 'date-field',
    fields: [],
    placeholder: 'Date of birth',
    // if no placeholder is given dateFormat is taken as placeholder
    fieldOptions: {
      allowClear: true,
      minDate: moment('01-06-2021', 'DD-MM-YYYY'),
      maxDate: moment('31-07-2022', 'DD-MM-YYYY'),
      // startMonth: moment('01-07-2015', 'DD-MM-YYYY').month(),
      // endMonth: moment('31-07-2016', 'DD-MM-YYYY').month(),
      // startYear: 2015,
      // endYear: 2016,
      dateFormat: 'DD-MM-YYYY'
    }
  };
  _exports.default = _default;
});