define("dummy/mirage/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.urlPrefix = ''; // make this `http://localhost:8080`, for example, if your API is on a different server

    this.namespace = ''; // make this `api`, for example, if your API is namespaced

    this.timing = 600; // delay for each request, automatically set to 0 during testing

    this.post('/users', function ()
    /*schema, request*/
    {
      return {
        user: {
          id: 1
        }
      };
    });
    this.get('/search_proofs', function () {
      return (
        /*schema, request*/
        {
          proofs: [{
            id: 'pancard',
            label: 'PAN Card'
          }, {
            id: 'aadharcard',
            label: 'Aadhar Card'
          }, {
            id: 'voterid',
            label: 'Voter Id'
          }]
        }
      );
    });
    this.get('/search_hobbies', function () {
      return (
        /*schema, request*/
        {
          hobbies: [{
            id: 'books',
            label: 'Books'
          }, {
            id: 'programming',
            label: 'Programming'
          }]
        }
      );
    });
    this.get('/search_programming_languages', function () {
      return (
        /*schema, request*/
        {
          programming_languages: [{
            id: 'java',
            label: 'Java'
          }, {
            id: 'javascript',
            label: 'Javascript'
          }, {
            id: 'c',
            label: 'C'
          }, {
            id: 'cplusplus',
            label: 'C++'
          }]
        }
      );
    });
    this.get('/search_locations', function () {
      return (
        /*schema, request*/
        {
          locations: [{
            id: 'india',
            label: 'India'
          }, {
            id: 'usa',
            label: 'USA'
          }, {
            id: 'germany',
            label: 'Germany'
          }, {
            id: 'uk',
            label: 'United Kingdom'
          }]
        }
      );
    });
    this.passthrough();
  }
});