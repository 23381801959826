define("dummy/components/sample-form/fields/date-picker-fields/time-picker-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'time',
    label: 'Time picker',
    editable: true,
    required: true,
    inputType: 'time-picker-field',
    fields: [],
    fieldOptions: {
      timeOnly: true,
      allowClear: true
    }
  };
  _exports.default = _default;
});