define("dummy/constants/autocomplete-fields/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'idProof',
    label: 'Search Id Proof',
    editable: true,
    required: true,
    inputType: 'auto-complete',
    fields: [],
    // optionValuePath: 'id',
    optionLabelPath: 'label',
    optionTargetPath: 'proofs',
    renderInPlace: true,
    link: '/search_proofs',
    fieldOptions: {
      multiple: false,
      creatable: false
    },
    placeholder: 'Search list of id proofs'
  };
  _exports.default = _default;
});