define("dummy/components/sample-form/fields/date-picker-fields/date-time-field", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'dateTime',
    label: 'Date & Time',
    editable: true,
    required: true,
    inputType: 'date-time-split-field',
    fields: [],
    fieldOptions: {
      allowClear: true,
      // minDate and maxDate should be given in localmoment
      // minDate: moment(this.get('moment').moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')
      minDate: (0, _moment.default)(),
      maxDate: (0, _moment.default)('2030-12-31', 'YYYY-MM-DD'),
      // taken from current account date format
      dateFormat: 'DD-MM-YYYY',
      twentyFourHrFormat: false,
      placeholder: {
        date: 'Select date',
        // if no placeholder is givem dateFormat is taken as placeholder
        time: 'Select time' // if no placeholder is give HH:MM is taken as placeholder

      }
    }
  };
  _exports.default = _default;
});