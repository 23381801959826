define("dummy/components/code-snippet/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    toggleState: false,
    actions: {
      toggle: function toggle() {
        Ember.set(this, 'toggleState', !this.toggleState);
      },
      copy: function copy(code) {
        navigator.clipboard.writeText(code).then(function () {
          console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
          console.error('Async: Could not copy text: ', err);
        });
      }
    }
  });

  _exports.default = _default;
});