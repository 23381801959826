define("dummy/models/user", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true),
    aboutMe: (0, _emberCpValidations.validator)('presence', true),
    gender: (0, _emberCpValidations.validator)('presence', true),
    degree: (0, _emberCpValidations.validator)('presence', true),
    dateOfBirth: (0, _emberCpValidations.validator)('presence', true),
    dateTime: (0, _emberCpValidations.validator)('presence', true),
    dateRange: (0, _emberCpValidations.validator)('presence', true),
    placeOfBirth: (0, _emberCpValidations.validator)('presence', true),
    languages: (0, _emberCpValidations.validator)('presence', true),
    idProof: (0, _emberCpValidations.validator)('presence', true),
    programmingLanguages: (0, _emberCpValidations.validator)('presence', true),
    locations: (0, _emberCpValidations.validator)('presence', true),
    hasAttachments: (0, _emberCpValidations.validator)('presence', true),
    hasClear: (0, _emberCpValidations.validator)('presence', true),
    hobbies: (0, _emberCpValidations.validator)('presence', true),
    jobType: (0, _emberCpValidations.validator)('presence', true),
    jobDesignation: (0, _emberCpValidations.validator)('presence', true),
    jobLevel: (0, _emberCpValidations.validator)('presence', true),
    addPaymentType: (0, _emberCpValidations.validator)('presence', true),
    bank: (0, _emberCpValidations.validator)('presence', true),
    products: (0, _emberCpValidations.validator)('presence', true),
    fsPlan: (0, _emberCpValidations.validator)('presence', true),
    fdPlan: (0, _emberCpValidations.validator)('presence', true),
    framework: (0, _emberCpValidations.validator)('presence', true),
    feFramework: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Model.extend(Validations, {
    name: attr('string'),
    aboutMe: attr('string'),
    gender: attr('string'),
    degree: attr('string'),
    dateOfBirth: attr('string'),
    dateTime: attr('string'),
    dateRange: attr({}),
    placeOfBirth: attr('string'),
    languages: attr('string'),
    idProof: attr('string'),
    programmingLanguages: attr('string'),
    locations: attr('string'),
    hasAttachments: attr('string'),
    hasClear: attr('string'),
    hobbies: attr('string'),
    jobType: attr('string'),
    jobDesignation: attr('string'),
    jobLevel: attr('string'),
    addPaymentType: attr('string'),
    bank: attr('string'),
    products: attr('string'),
    fsPlan: attr('string'),
    fdPlan: attr('string'),
    framework: attr('string'),
    feFramework: attr('string')
  });

  _exports.default = _default;
});